<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="taxModal"
    >   
        <CForm>
            <CRow>
              <CCol md="6">
                <CSelect
                  label="Tipo:"
                  :isValid="checkIfValid('type')"
                  :value.sync="$v.form.type.$model"
                  :options="typesOptions"
                  invalidFeedback="Este campo es requerido."
                  @change="changeType"
                />
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Impuesto:"
                  :isValid="checkIfValid('code')"
                  :value.sync="$v.form.code.$model"
                  :options="taxesOptions"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CSelect
                  label="Tipo Factor:"
                  :isValid="checkIfValid('factor')"
                  :value.sync="$v.form.factor.$model"
                  :options="factorsOptions"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Tasa (%):"
                  :lazy="false"
                  :value.sync="$v.form.rate.$model"
                  :isValid="checkIfValid('rate')"
                  placeholder=""
                  autocomplete="rate"
                  invalidFeedback="Este campo es requerido."
              />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default { 
  name: 'TaxModal',
  props: {

  },
  
  data () {
    return {
      taxModal: false,
      title: "Nuevo Impuesto", 
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      taxesOptions: [{label: 'ISR', value: '001'}, {label: 'IVA', value: '002'}, {label: 'IEPS', value: '003'}],
      typesOptions: [{label: 'Traslado', value: 'TRASLADO'}, {label: 'Retención', value: 'RETENCION'}],
      factorsOptions: [{label: 'Tasa', value: 'TASA'}, {label: 'Cuota', value: 'CUOTA'}, {label: 'Exento', value: 'EXENTO'}]
    }
  },
  methods: {
    async changeType () {

    },
    storeModal () {
      this.taxModal = true;
      this.color = "info";
      this.title = 'Nuevo Impuesto';
      this.isEdit = false;
      this.submitted = false;
      this.form = this.getEmptyForm();
    },
    updateModal (item) {
      this.taxModal = true;
      this.color = "warning";
      this.title = 'Editar Impuesto';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          type: item.type,
          code: item.code,
          factor: item.factor,
          rate: (item.rate * 100).toFixed(4)
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.taxModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          type: 'TRASLADO',
          code: '002',
          factor: 'TASA',
          rate: '',
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      rate: {
        required
      },
      type: {
        required,
        minLength: minLength(3)
      },
      factor: {
        required,
        minLength: minLength(3)
      },
      code: {
        required,
        minLength: minLength(3)
      }
    }
  },
}
</script>