<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-group"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Grupo de Impuestos</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable 
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #name="{item}" class="w200"><td>{{item.name}}</td></template>
        <template #taxes="{item}">
          <td class="w200">
            <span v-for="tax in item.taxes">
              {{ tax.name }} {{ tax.factor == "EXENTO" ? "Exento" : ""}} {{ tax.type == "RETENCION" ? "RET" : ""}} {{ (tax.rate * 100).toFixed(2) }}%<br>
            </span>
          </td>
        </template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <TaxGroupModal ref="taxGroupModal" @store="storeProcess" @update="updateProcess"></TaxGroupModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import TaxGroupModal from './TaxGroupModal.vue'
import DeleteModal from '../../global/DeleteModal.vue'
import ws from '../../../services/taxes';
import store from '../../../store'

export default {
  name: 'TaxGroupsTable',
  components: { TaxGroupModal, DeleteModal }, 
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Nombre'},
          {key: 'taxes', label: 'Impuestos'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: []
    }
  },
  methods: {
    storeModal () {
      this.$refs.taxGroupModal.storeModal();
    },
    updateModal (item) {
      this.$refs.taxGroupModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Grupo de Impuestos", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.storeTaxGroup(data);
      
      if(response.type == "success"){
        this.$emit("refreshTaxGroups", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.updateTaxGroup(data);

      if(response.type == "success"){
        this.$emit("refreshTaxGroups", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.deleteTaxGroup(id);
      
      if(response.type == "success"){
        this.$emit("refreshTaxGroups", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  }
}
</script>
