<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TaxesTable
          :items="taxes"
          hover
          striped
          border
          small
          fixed
          caption="Impuestos"
          @refreshTaxes="refreshTaxes"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <TaxGroupsTable
          :items="taxGroups"
          hover
          striped
          border
          small
          fixed
          caption="Grupos de Impuestos"
          @refreshTaxGroups="refreshTaxGroups"
        />
      </CCol>
    </CRow>
    <CRow v-if="has_local_taxes">
      <CCol sm="12">
        <LocalTaxesTable
          :items="local_taxes"
          hover
          striped
          border
          small
          fixed
          caption="Impuestos Locales"
          @refreshLocalTaxes="refreshLocalTaxes"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TaxesTable from '../../components/configuration/taxes/TaxesTable.vue';
import TaxGroupsTable from '../../components/configuration/taxes/TaxGroupsTable.vue';
import LocalTaxesTable from '../../components/configuration/taxes/LocalTaxesTable.vue';
import ws from '../../services/taxes';
import general_ws from '../../services/general';
import store from '../../store'

export default {
  name: 'Taxes',
  components: { TaxesTable, TaxGroupsTable, LocalTaxesTable },
  data: function () {
		return {
            taxes: [],
            taxGroups: [],
            has_local_taxes: false,
            local_taxes: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get(); 

    if(response.type == "success"){
      this.taxes = response.data;
    }

    response = await ws.getTaxGroups(); 

    if(response.type == "success"){
      this.taxGroups = response.data;
    }

    response = await ws.getLocalTaxes(); 

    if(response.type == "success"){
      this.local_taxes = response.data;
    }

    response = await general_ws.hasAddenda("local_taxes"); 

    if(response.type == "success"){
      this.has_local_taxes = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshTaxes (items){
      this.loading();
      let response = await ws.get();

      if(response.type == "success"){
        this.taxes = response.data;
      }
      this.loaded();
    },
    async refreshTaxGroups (items){
      this.loading();
      let response = await ws.getTaxGroups();

      if(response.type == "success"){
        this.taxGroups = response.data;
      }
      this.loaded();
    },
    async refreshLocalTaxes (items){
      this.loading();
      let response = await ws.getLocalTaxes();

      if(response.type == "success"){
        this.local_taxes = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
