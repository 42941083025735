<template>
    <CModal
      :title="title"
      :color="color"
      size="sm"
      :show.sync="taxGroupModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <table style="width: 100%;">
                  <tr v-for="(tax, index) in taxes" :key="tax.id">
                    <td>{{ tax.name }} {{ tax.factor == "EXENTO" ? "Exento" : ""}} {{ tax.type == "RETENCION" ? "RET" : ""}} {{ (tax.rate * 100).toFixed(2) }}%</td>
                    <td style="width: 50px;">
                      <CSwitch
                        v-model="taxes[index].selected"
                        :checked.sync="taxes[index].selected"
                        class="mx-1"
                        color="success"
                        name="switch1"
                        @update:checked="toggle(index)"
                      />
                    </td>
                  </tr>
                </table>
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import ws from '../../../services/taxes';

export default { 
  name: 'TaxGroupModal',
  props: {

  },
  
  data () {
    return {
      taxGroupModal: false,
      title: "Nuevo Grupo de Impuestos", 
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      taxes: [],
      selected_taxes: []
    }
  },
  methods: {
    async storeModal () {
      this.taxGroupModal = true;
      this.color = "info";
      this.title = 'Nuevo Grupo de Impuestos';
      this.isEdit = false;
      this.submitted = false;

      this.getTaxes();

      this.form = this.getEmptyForm();
    },
    updateModal (item) {
      this.taxGroupModal = true;
      this.color = "warning";
      this.title = 'Editar Grupo de Impuestos';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          taxes: []
      };
    },
    store () {
      let _this = this;

      _this.selected_taxes = [];

      let n = 0;
      this.taxes.forEach(function(tax){
        if(_this.taxes[n].selected){
          _this.selected_taxes.push(tax.id);
        }
        n++;
      });

      this.form.taxes = _this.selected_taxes;

      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    toggle (index) {

    },
    closeModal () {
      this.taxGroupModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          taxes: []
      }
    },
    async getTaxes() {
      let response = await ws.get(); 

      if(response.type == "success"){
        this.taxes = response.data;

        let _this = this;

        let n = 0;
        this.taxes.forEach(function(tax){
          tax.selected = false;
          _this.taxes[n] = tax;
          n++;
        });

        this.selected_taxes = [];
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },
}
</script>